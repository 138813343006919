import React, { useState, useEffect } from 'react';
import SaveButton from './SaveButton';
import LoadingMask from './LoadingMask';
import { Stack } from '@mui/material';

const StandardEditPanel = ({ load, handleSave, children }) => {

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        load().then(() => setLoading(false))
    }, []);

    const onSave = () => {
        if (saving) {
            return;
        }
        setSaving(true);
        handleSave().then(() => setSaving(false))
    };

    return (
        <>
            {loading
                ?
                <LoadingMask />
                :
                <>
                    <LoadingMask visible={saving} />
                    <SaveButton onClick={onSave}/>
                    <Stack spacing={2}>
                        {children}
                    </Stack>
                </>
            }
        </>
    );
};

export default StandardEditPanel;
