import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingMask from '../components/LoadingMask';
import PlayerSelectDialog from '../player/PlayerSelectDialog';
import CreateButton from '../components/CreateButton';

export default function GroupPlayerList({ group }) {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showPlayerSelectDialog, setShowPlayerSelectDialog] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        axios.get(`/group/${group.id}/members`)
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast.error('Error loading members');
                setLoading(false);
                console.error(error);
            });
    };

    const addPlayer = (player) => {
        setShowPlayerSelectDialog(false);
        const qs = require('qs');
        axios.post(`/entitlement/${group.id}`, qs.stringify({ 'playerId': player.id }))
            .then(response => {
                loadData();
                toast.success('Player Added');
            })
            .catch(error => {
                toast.error('Error adding player');
                console.error(error);
            });
    };

    const onDeleteClick = (index) => {
        const items = [...data];
        const item = items[index];
        deletePlayer(item);
    };

    const deletePlayer = (player) => {
        axios.delete(`/entitlement/${group.id}?playerId=${player.id}`)
            .then(response => {
                loadData();
                toast.success('Player Deleted');
            })
            .catch(error => {
                toast.error('Error deleting player');
                console.error(error);
            });
    };

    return (
        <>
            {showPlayerSelectDialog && <PlayerSelectDialog onSelect={addPlayer} onClose={() => setShowPlayerSelectDialog(false)} />}
            <Typography variant='h5'>Members</Typography>
            <CreateButton label='Add Player' onClick={() => setShowPlayerSelectDialog(true)} />
            <TableContainer component={Paper} sx={{
                minHeight: '400px',
                maxHeight: '400px',
                overflowY: 'auto'
            }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>IGN</TableCell>
                            <TableCell sx={{ width: '2%' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <LoadingMask visible={loading} fullScreen={false} />
                        {!loading && data.map((item, index) => (
                            <TableRow key={item}>
                                <TableCell>{item.ign}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => onDeleteClick(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
