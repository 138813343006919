import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography } from '@mui/material';
import { toast } from 'react-toastify';

export default function Version() {

    const [version, setVersion] = useState("");

    useEffect(() => {
        axios.get('/version')
            .then(response => {
                setVersion(response.data);
            })
            .catch(error => {
                toast.error('Error fetching version');
                console.error(error);
            });
    }, []);

    return (
        <Typography>{version}</Typography>
    );
}
