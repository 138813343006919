import { Breadcrumbs } from '@mui/material';
import React from 'react';
import Link from '@mui/material/Link';
import { Link as RouterLink, useLocation, } from 'react-router-dom';

const breadcrumbNameMap = {
    '/group': 'Groups',
    '/email': 'Emails',
};

function LinkRouter(props) {
    return <Link {...props} component={RouterLink} />;
}

export default function BreadcrumbTrail() {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
    if (pathnames.length === 1) {
        return null;
    }
    return (
        <Breadcrumbs separator='>'>
            {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                return last ? (
                    null
                ) : (
                    <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                        {breadcrumbNameMap[to] || value}
                    </LinkRouter>
                );
            })}
        </Breadcrumbs>
    );
};