import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';
import StandardEditPanel from '../components/StandardEditPanel';
import GroupPlayerList from './GroupPlayerList';

const GroupForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        id: null,
        name: "",
        description: "",
    });

    useEffect(() => {
        loadData();
    }, [id]);

    const loadData = async () => {
        if (id !== 'new') {
            try {
                const response = await axios.get(`/group/${id}`);
                setFormData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error("Error loading group");
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSave = async () => {
        if (id === 'new') {
            try {
                const response = await axios.put(`/group`, formData)
                toast.success("Saved")
                navigate(`/group/${response.data.id}`)
            } catch (error) {
                console.error('Error saving group:', error);
                toast.error("Error saving group");
            }
        } else {
            try {
                await axios.post(`/group/${id}`, formData)
                toast.success("Saved")
            } catch (error) {
                console.error('Error saving group:', error);
                toast.error("Error saving group");
            }
        }
    };

    return (
        <StandardEditPanel load={loadData} handleSave={handleSave}>
            <TextField
                name="identifier"
                label="Identifier"
                value={formData.identifier}
                onChange={handleChange}
            />
            <TextField
                name="name"
                label="Name"
                value={formData.name}
                onChange={handleChange}
            />
            <TextField
                multiline
                rows={5}
                name="description"
                label="Description"
                value={formData.description}
                onChange={handleChange}
            />
            {formData.id > 0 && <GroupPlayerList group={formData} />}
        </StandardEditPanel>
    );
};

export default GroupForm;
