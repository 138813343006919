import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    height: 93.5%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Container = styled.div`
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Text = styled.h1`
    font-size: 12rem;
    color: #133341;
`

class PageNotFound extends Component {
    render() {
        return (
            <Wrapper>
                <Container>
                    <Text>404</Text>
                </Container>
            </Wrapper>
        )
    }
}

export default PageNotFound;