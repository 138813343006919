import React from 'react';
import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

export default function SaveButton({ onClick, disabled = false }) {
    return (
        <Button
            component="label"
            variant="text"
            startIcon={<SaveIcon />}
            onClick={onClick}
            disabled={disabled}
        >
            Save
        </Button>
    );
}
