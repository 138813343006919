import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

const Container = styled.div`
    position: ${props => props.fullScreen ? 'fixed' : 'absolute'};
    top: ${props => props.fullScreen ? '0' : '50%'};
    left: ${props => props.fullScreen ? '0' : '50%'};
    width: ${props => props.fullScreen ? '100%' : ''};
    height: ${props => props.fullScreen ? '100%' : ''};
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export default function LoadingMask({ visible = true, fullScreen = true }) {
    return (
        <>
            {visible && <Container fullScreen={fullScreen}>
                <CircularProgress />
            </Container>}
        </>
    );
}
