import React from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchButton({ onClick, large = false, tooltip, disabled = false }) {
    return (
        (large ?
            <Button
                variant="text"
                onClick={onClick}
            >
                Search
            </Button>
            :
            <Tooltip title={tooltip}>
                <IconButton
                    type="button"
                    onClick={onClick}
                    disabled={disabled}
                >
                    <SearchIcon />
                </IconButton >
            </Tooltip >
        )
    );
}
