import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { IconButton, Toolbar } from '@mui/material';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import Version from './Version';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#about-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const AboutDialog = ({ onClose }) => {

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="about-dialog-title"
            scroll={"paper"}
            open={alert !== null}
            PaperComponent={PaperComponent}
        >
            <Toolbar
                id="about-dialog-toolbar"
                sx={{
                    bgcolor: 'primary.main',
                    color: 'white'
                }}
            >
                <DialogTitle id="about-dialog-title" sx={{ cursor: 'move' }}>About</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar >
            <DialogContent dividers={true}>
                <Version />
            </DialogContent>
            <DialogActions>
                <Button fullWidth onClick={onClose}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AboutDialog;
