import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import LoadingMask from '../components/LoadingMask';

export default function EmailList() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newTextLine, setNewTextLine] = useState("");

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        axios.get('/failedentitlementemail')
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast.error('Error loading emails');
                setLoading(false);
                console.error(error);
            });
    };

    const handleValueEdit = (index, value) => {
        const items = [...data];
        items[index] = value;
        setData(items);
    };

    const onDeleteClick = (index) => {
        const items = [...data];
        const item = items[index];
        deleteEmail(item);
        items.splice(index, 1);
        setData(items);
    };

    const deleteEmail = (email) => {
        axios.delete(`/failedentitlementemail/${email}`)
            .then(response => {
                toast.success('Email Deleted');
            })
            .catch(error => {
                toast.error('Error deleting email');
                console.error(error);
            });
    };

    const handleNewTextLineTextChange = (e) => {
        setNewTextLine(e.target.value);
    }

    const onNewLineEnter = (e) => {
        if (e.key === 'Enter') {
            addLine();
        }
    };

    const addLine = () => {
        const items = [...data];
        items.push(newTextLine);
        saveEmail(newTextLine);
        setNewTextLine("");
        setData(items);
    };

    const saveEmail = (email) => {
        const qs = require('qs');
        axios.post('/failedentitlementemail', qs.stringify({ 'email': email }))
            .then(response => {
                toast.success('Email Added');
            })
            .catch(error => {
                toast.error('Error adding email');
                console.error(error);
            });
    };

    return (
        <>
            <Typography variant='h4'>Emails</Typography>
            <Typography variant='body1' gutterBottom>This is a list of emails which will get an alert when an entitlement check fails</Typography>
            {loading
                ?
                <LoadingMask />
                :
                <TableContainer component={Paper}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell sx={{ width: '2%' }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((item, index) => (
                                <TableRow key={item}>
                                    <TableCell>
                                        <TextField
                                            size='small'
                                            value={item}
                                            onChange={(e) => handleValueEdit(index, e.target.value)}
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <DeleteIcon sx={{ cursor: 'pointer' }} onClick={() => onDeleteClick(index)} />
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        autoFocus
                                        label="New Email"
                                        size="small"
                                        variant="outlined"
                                        value={newTextLine}
                                        onChange={handleNewTextLineTextChange}
                                        onKeyDown={onNewLineEnter}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton color="inherit" onClick={addLine}>
                                        <AddIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    );
}
