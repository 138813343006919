// AlertContext.js
import React, { createContext, useContext, useState, useCallback } from 'react';
import AlertDialog from '../pages/components/AlertDialog';
import ConfirmDialog from '../pages/components/ConfirmDialog';

const AlertContext = createContext();

export const useAlert = () => {
    const { setAlert } = useContext(AlertContext);

    const showAlert = useCallback((title, message) => {
        setAlert({ title, message });
    }, [setAlert]);

    return showAlert;
};

export const useConfirm = () => {
    const { setConfirm } = useContext(AlertContext);

    const showConfirm = useCallback((title, message, onConfirm) => {
        setConfirm({ title, message, onConfirm });
    }, [setConfirm]);

    return showConfirm;
};

export const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState(null);
    const [confirm, setConfirm] = useState(null);

    const handleAlertClose = useCallback(() => {
        setAlert(null);
    }, []);

    const handleConfirm = useCallback(() => {
        const onConfirm = confirm.onConfirm;
        setConfirm(null);
        onConfirm();
    }, [confirm]);

    const handleConfirmClose = useCallback(() => {
        setConfirm(null);
    }, []);

    return (
        <AlertContext.Provider value={{ setAlert, setConfirm }}>
            {children}
            <AlertDialog alert={alert} onClose={handleAlertClose} />
            <ConfirmDialog prompt={confirm} onConfirm={handleConfirm} onClose={handleConfirmClose} />
        </AlertContext.Provider>
    );
};
