import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'

import './index.css';

import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';

import { toast } from 'react-toastify';

axios.defaults.baseURL = '/api';

axios.interceptors.request.use(
  function (config) {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      config.headers.authentication = authToken;
    }
    return config;
  }
);

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('displayName');
      window.location.href = '/login';
    } else if (error.response && error.response.status === 504) {
      toast.error('A request has timed out');
    }
    return Promise.reject(error);
  }
);

ReactDOM.render((
  <BrowserRouter>
    <App />
  </BrowserRouter>
), document.getElementById('root'));
