import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import { Divider, Toolbar } from '@mui/material';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#confirm-dialog-toolbar"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const ConfirmDialog = ({ prompt, onConfirm, onClose }) => {
    const lines = prompt?.message?.split('\n');

    return (
        <Dialog
            open={prompt !== null}
            onClose={onClose}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
            PaperComponent={PaperComponent}
        >
            <Toolbar
                id="confirm-dialog-toolbar"
                sx={{
                    cursor: 'move',
                    bgcolor: 'primary.main',
                    color: 'white'
                }}
            >
                <DialogTitle id="confirm-dialog-title">
                    {prompt?.title}
                </DialogTitle>
            </Toolbar >
            <DialogContent dividers={true}>
                <DialogContentText id="confirm-dialog-description">
                    {lines?.map((line, index) => (
                        <Typography key={index} gutterBottom>{line}</Typography>
                    ))}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button fullWidth onClick={onConfirm}>Yes</Button>
                <Divider orientation="vertical" flexItem />
                <Button fullWidth onClick={onClose}>No</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
