import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled as muiStyled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import logo from './rsc/server-logo.png';

const ErrorMessage = styled.span`
    background-color: red;
    color: white;
    padding-top: 2px;
    padding-bottom: 2px;
`;

const LoginWrapper = styled.div`
    margin-top: -55px;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #adf1ff;
`

const LoginContainer = styled.div`
    width: 650px;
    height: auto;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: #6EC7D2;
    align-items: center;
    box-shadow: 0px 1px 11px 5px rgba(0, 0, 0, 0.65);

    @media (max-width: 768px) {
        width: 80%;
    }
`;

const LoginImage = styled.img`
    width: 200px;
    height: auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-top: 20px;
`;

const LoginForm = muiStyled(FormGroup)`
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 20px;
    & > * {
        margin-bottom: 10px;
    }
`;

const LoginButton = muiStyled(Button)`
    background: #133341;
    color: white;
    font-size: 1.5em;
`;

export default function Login() {
    const navigate = useNavigate();

    const [state, setState] = useState({
        username: "",
        password: ""
    });

    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [capsLockOn, setCapsLockOn] = useState(false);

    useEffect(() => {
        const storedToken = localStorage.getItem('authToken');
        if (storedToken) {
            navigate('/dashboard');
        }
    }, [navigate]);

    const handleChange = (e) => {
        setErrorMessage(null);
        const { id, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    const handleKeyUp = (event) => {
        if (event.getModifierState('CapsLock')) {
            setCapsLockOn(true);
        } else {
            setCapsLockOn(false);
        }
    };

    const simulateKeyEvent = () => {
        const event = new KeyboardEvent('keydown', { key: 'A' });
        handleKeyUp(event);
    };

    useEffect(() => {
        window.addEventListener('keyup', handleKeyUp);

        simulateKeyEvent();

        return () => {
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    const handleLogin = () => {
        const qs = require('qs');
        setLoading(true);
        axios.post('/authentication/login', qs.stringify({ 'username': state.username, 'password': state.password }))
            .then(function (response) {
                const loginResponse = response.data;
                setLoading(false);
                if (loginResponse.success) {
                    setErrorMessage(null)
                    const authToken = response.headers['authentication'];
                    localStorage.setItem('authToken', authToken);
                    localStorage.setItem('displayName', loginResponse.displayName);
                    localStorage.setItem('user', JSON.stringify(loginResponse.user));
                    navigate('/');
                } else {
                    setErrorMessage(loginResponse.message);
                }
            })
            .catch(function (error) {
                setLoading(false);
                alert(error);
            })
    };

    return (
        <LoginWrapper>
            <LoginContainer>
                <LoginImage id='login-logo-img' src={logo} alt='' />
                <LoginForm>
                    <TextField
                        autofocus
                        id='username'
                        name="username"
                        label="Username"
                        variant='standard'
                        value={state.username}
                        onChange={handleChange}
                        onKeyDown={handleKeyPress}
                        onKeyUp={handleKeyUp}
                    />
                    <TextField
                        id='password'
                        name="username"
                        type="password"
                        label='Password'
                        variant='standard'
                        value={state.password}
                        onChange={handleChange}
                        onKeyDown={handleKeyPress}
                        onKeyUp={handleKeyUp}
                    />
                    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                    {capsLockOn && <ErrorMessage>Caps Lock is on!</ErrorMessage>}
                    <LoginButton variant="contained" onClick={handleLogin}>Login</LoginButton>
                </LoginForm>
            </LoginContainer>
        </LoginWrapper>
    );
}