import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import DialogContentText from '@mui/material/DialogContentText';
import { Toolbar } from '@mui/material';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#alert-dialog-toolbar"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const AlertDialog = ({ alert, onClose }) => {
    const lines = alert?.message?.split('\n');

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            scroll={"paper"}
            open={alert !== null}
            maxWidth='md'
            PaperComponent={PaperComponent}
        >
            <Toolbar
                id="alert-dialog-toolbar"
                sx={{
                    cursor: 'move',
                    bgcolor: 'primary.main',
                    color: 'white'
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {alert?.title}
                </DialogTitle>
            </Toolbar >
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers={true}>
                <DialogContentText id="alert-dialog-description">
                    {lines?.map((line, index) => (
                        <Typography key={index} gutterBottom>{line}</Typography>
                    ))}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button fullWidth autoFocus onClick={onClose}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;
