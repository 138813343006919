import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const navbarConfig = [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "Groups",
        path: "/group"
    },
    {
        label: "Emails",
        path: "/email"
    },
];

const Navbar = ({ isVisible, onHide }) => {
    const handleClose = () => {
        onHide();
    };

    return (
        <Drawer open={isVisible} onClose={handleClose}>
            <Box sx={{ width: 250 }} role="presentation">
                <List>
                    {navbarConfig.map((item, index) => (
                        <ListItem key={`sub-item-${index}`} disablePadding sx={{ width: '230px'}}>
                            <ListItemButton onClick={handleClose} component={Link} to={item.path}>
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>
    );
};

export default Navbar;
