import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import Header from './pages/components/Header';

import PageNotFound from './pages/404';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import 'react-toastify/dist/ReactToastify.css';
import { AlertProvider } from './alert/AlertContext';
import GroupList from './pages/group/GroupList';
import GroupForm from './pages/group/GroupForm';
import EmailList from './pages/email/EmailList';
import { Box } from '@mui/material';
import BreadcrumbTrail from './pages/components/BreadcrumbTrail';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const MainContent = styled.div`
  flex: ${props => props.isLoggedIn ? '1' : '0'};
  overflow: ${props => props.isLoggedIn ? 'auto' : 'hidden'};
`;

const App = () => {

  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);

  const setBackgroundColor = (color) => {
    document.body.style.backgroundColor = color;
  };

  const setThemeColor = (color) => {
    document.querySelector('meta[name="theme-color"]').setAttribute('content', color);
  };

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (!!authToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      navigate('/login');
    }
    setAuthChecked(true);
  }, [navigate]);

  useEffect(() => {
    if (authChecked) {
      if (isLoggedIn) {
        setBackgroundColor('white');
        setThemeColor('#1976D2');
      } else {
        setBackgroundColor('#adf1ff');
        setThemeColor('#adf1ff');
      }
      const delay = setTimeout(() => {
        if (isLoggedIn) {
          setBackgroundColor('white');
          setThemeColor('#1976D2');
        } else {
          setBackgroundColor('#adf1ff');
          setThemeColor('#adf1ff');
        }
      }, 100);

      return () => clearTimeout(delay);
    }
  }, [isLoggedIn, authChecked]);

  return (
    <AppContainer isLoggedIn={isLoggedIn}>
      <AlertProvider>
        {isLoggedIn && (
          <>
            <Header />
            <Box sx={{ height: '55px' }} />
            <BreadcrumbTrail />
          </>
        )}
        <MainContent isLoggedIn={isLoggedIn}>
          <Routes>
            <Route index element={<Dashboard />} />
            <Route path='*' element={<PageNotFound />} />
            <Route path='/login' element={<Login />} />
            <Route path='/group' element={<GroupList />} />
            <Route path='/group/:id' element={<GroupForm />} />
            <Route path='/email' element={<EmailList />} />
          </Routes>
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </MainContent>
      </AlertProvider>
    </AppContainer>
  );
};

export default App;
