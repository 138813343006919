import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import CreateButton from '../components/CreateButton';

export default function GroupList() {
    const [groups, setGroups] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('/group')
            .then(response => {
                setGroups(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const handleClick = (group) => {
        navigate(`/group/${group.id}`);
    };

    const handleNewClick = () => {
        navigate(`/group/new`);
    };

    return (
        <>
            <Typography variant="h4">Groups</Typography>
            <Typography variant='body1' gutterBottom>This is a list of entitlement groups which players can be assigned to</Typography>
            <CreateButton onClick={handleNewClick} tooltip='Create New Group' />
            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '2%' }}>ID</TableCell>
                            <TableCell sx={{ width: '2%' }}>Identifier</TableCell>
                            <TableCell>Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {groups.map((group) => (
                            <TableRow
                                hover
                                key={group.id}
                                onClick={(event) => handleClick(group)}
                                sx={{ cursor: 'pointer' }}
                            >
                                <TableCell>{group.id}</TableCell>
                                <TableCell>{group.identifier}</TableCell>
                                <TableCell>{group.name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
