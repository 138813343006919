import React, { useState } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AboutDialog from './AboutDialog';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';

export default function Header() {

    const [isNavbarVisible, setIsNavbarVisible] = useState(false);
    const [showAboutVisible, setShowAboutVisible] = useState(false);

    const toggleNavbar = () => {
        setIsNavbarVisible(!isNavbarVisible);
    };

    const hideNavbar = () => {
        setIsNavbarVisible(false);
    };

    const handleLogout = async () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('displayName');
        localStorage.removeItem('user');
        await axios.get('/authentication/logout');
        navigate('/login');
    };

    const squareHighlightStyle = {
        width: '60px',
        height: '60px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '0',
        padding: '0',
    };

    return (
        <Box>
            <Navbar isVisible={isNavbarVisible} onHide={hideNavbar} />
            {showAboutVisible && <AboutDialog onClose={() => setShowAboutVisible(false)} />}
            <AppBar elevation={0}>
                <Toolbar variant='dense'
                    sx={{
                        "@media (min-width: 0px)": { paddingRight: 0, paddingLeft: 0 },
                    }}>
                    <IconButton
                        size="large"
                        color="inherit"
                        type='button'
                        onClick={toggleNavbar}
                        style={squareHighlightStyle}
                    >
                        <MenuIcon />
                    </IconButton>
                    <IconButton
                        size="large"
                        color="inherit"
                        type='button'
                        style={{
                            ...squareHighlightStyle,
                            width: 'null',
                            paddingLeft: '10px',
                            paddingRight: '10px'
                        }}
                        component={Link}
                        to="/"
                    >
                        <Typography variant="h6" component="div">
                            EquestriCraft Entitlement Service
                        </Typography>
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton
                        size="large"
                        type='button'
                        onClick={() => setShowAboutVisible(true)}
                        color="inherit"
                        style={squareHighlightStyle}
                    >
                        <InfoIcon />
                    </IconButton>
                    <IconButton
                        size="large"
                        type='button'
                        onClick={() => handleLogout()}
                        color="inherit"
                        style={squareHighlightStyle}
                    >
                        <LogoutIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Box>
    )
}