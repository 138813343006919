import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';

export default function CreateButton({ onClick, label = 'Create', disabled = false }) {
    return (
        <Button
            component="label"
            variant="text"
            startIcon={<AddIcon />}
            onClick={onClick}
            disabled={disabled}
        >
            {label}
        </Button>
    );
}
